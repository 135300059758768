<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <!-- Reset Password v1 -->
            <b-card class="mb-0">
                <!-- logo -->
                <b-link class="brand-logo">
                    <img
                        src="@/assets/images/logo/logo.png"
                        alt="Logo"
                        class="logo-auth"
                    />
                    <h2 class="brand-text text-primary ml-1">Leechall.io</h2>
                </b-link>

                <b-card-title class="mb-1"> Reset Password 🔒 </b-card-title>
                <b-card-text class="mb-2">
                    Your new password must be different from previously used
                    passwords
                </b-card-text>

                <!-- alert -->
                <b-alert :variant="variant" show v-if="status">
                    <div class="alert-body">{{ status }}</div>
                </b-alert>

                <!-- form -->
                <validation-observer ref="resetForm">
                    <b-form
                        method="POST"
                        class="auth-reset-password-form mt-2"
                        @submit.prevent="onSubmit"
                    >
                        <!-- email -->
                        <b-form-group label-for="email" label="Email">
                            <validation-provider
                                #default="{ errors }"
                                name="email"
                                rules="required|email"
                            >
                                <b-form-input
                                    id="email"
                                    v-model="email"
                                    name="login-email"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="john@example.com"
                                    autofocus
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- password -->
                        <b-form-group
                            label="New Password"
                            label-for="reset-password-new"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="password"
                                vid="password"
                                rules="required|min:6"
                            >
                                <b-input-group
                                    class="input-group-merge"
                                    :class="
                                        errors.length > 0 ? 'is-invalid' : null
                                    "
                                >
                                    <b-form-input
                                        id="reset-password-new"
                                        v-model="password"
                                        :type="password1FieldType"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        class="form-control-merge"
                                        name="reset-password-new"
                                        placeholder="············"
                                    />
                                    <b-input-group-append is-text>
                                        <feather-icon
                                            class="cursor-pointer"
                                            :icon="password1ToggleIcon"
                                            @click="togglePassword1Visibility"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- confirm password -->
                        <b-form-group
                            label-for="reset-password-confirm"
                            label="Confirm Password"
                        >
                            <validation-provider
                                #default="{ errors }"
                                name="Confirm Password"
                                rules="required|confirmed:password"
                            >
                                <b-input-group
                                    class="input-group-merge"
                                    :class="
                                        errors.length > 0 ? 'is-invalid' : null
                                    "
                                >
                                    <b-form-input
                                        id="reset-password-confirm"
                                        v-model="password_confirmation"
                                        :type="password2FieldType"
                                        class="form-control-merge"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        name="reset-password-confirm"
                                        placeholder="············"
                                    />
                                    <b-input-group-append is-text>
                                        <feather-icon
                                            class="cursor-pointer"
                                            :icon="password2ToggleIcon"
                                            @click="togglePassword2Visibility"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- overlay -->
                        <b-overlay
                            :show="loading"
                            rounded
                            opacity="0.6"
                            spinner-variant="primary"
                        >
                            <!-- submit button -->
                            <b-button block type="submit" variant="primary">
                                Set New Password
                            </b-button>
                        </b-overlay>
                    </b-form>
                </validation-observer>

                <p class="text-center mt-2">
                    <b-link :to="{ name: 'auth-login' }">
                        <feather-icon icon="ChevronLeftIcon" /> Back to login
                    </b-link>
                </p>
            </b-card>
            <!-- /Reset Password v1 -->
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
    BAlert,
    BOverlay,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    BFormInput,
    BButton,
} from "bootstrap-vue";
import { required } from "@validations";
import axios from "axios";

export default {
    components: {
        BAlert,
        BOverlay,
        BCard,
        BButton,
        BCardTitle,
        BCardText,
        BForm,
        BFormGroup,
        BInputGroup,
        BLink,
        BFormInput,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            email: "",
            password: "",
            password_confirmation: "",
            variant: "",
            status: "",
            loading: false,
            // validation
            required,

            // Toggle Password
            password1FieldType: "password",
            password2FieldType: "password",
        };
    },
    computed: {
        password1ToggleIcon() {
            return this.password1FieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon";
        },
        password2ToggleIcon() {
            return this.password2FieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon";
        },
    },
    methods: {
        togglePassword1Visibility() {
            this.password1FieldType =
                this.password1FieldType === "password" ? "text" : "password";
        },
        togglePassword2Visibility() {
            this.password2FieldType =
                this.password2FieldType === "password" ? "text" : "password";
        },
        async onSubmit() {
            this.status = "";
            this.loading = true;
            try {
                const response = await axios.post("/auth/reset-password", {
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    token: this.$route.params.token,
                });
                this.loading = false;
                if (response.data.success) {
                    this.variant = "success";
                    this.status = response.data.message;
                } else {
                    this.variant = "danger";
                    this.status = response.data.message;
                }
            } catch (error) {
                if (error.response.data.errors != undefined)
                    this.$refs.resetForm.setErrors(error.response.data.errors);
            }
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
